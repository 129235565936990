








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LabelChart extends Vue {
  @Prop({default: 'white'})
  private color!: 'blue' | 'pink' | 'gray'

  private get getColor(){
    switch(this.color) {
      case 'blue':
        return '#4277C8'
      case 'pink':
        return '#FF7B62'
      case 'gray':
        return '#9DA3AA'
      default:
       return 'white'
    }
  }

}
