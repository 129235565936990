




























import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator'
import DonutOverview from '@/components/organisms/quiz/DonutOverview.vue'
import LabelChart from '@/components/atoms/quiz/LabelChart.vue'
import ButtonNext from '@/components/atoms/quiz/ButtonNext.vue'
import { CurriculumType, ResultUnitType } from '@/types/quiz/units'
import UnitQuiz from '@/mixins/quiz/UnitQuiz'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import ModalShowBadge from '@/components/organisms/quiz/ModalShowBadge.vue'
import LabelNameBook from '@/components/atoms/quiz/LabelNameBook.vue'

@Component({
  components: {
    DonutOverview,
    LabelChart,
    ButtonNext,
    ModalShowBadge,
    LabelNameBook,
  },
})
export default class Overview extends Mixins(UnitQuiz, BaseQuiz) {
  private readonly stepBack = {
    nonPageResultDrill: -1,
    hasPageResultDrill: -2,
  }

  @Ref() modalBadge!: ModalShowBadge
  private get curriculum(): CurriculumType {
    return this.$store.getters['question/curriculum']
  }
  private get result(): ResultUnitType {
    return this.$store.getters['question/result']
  }

  private get onlyFlashCard() {
    return !!this.$route.query.onlyFlashCard
  }

  private get calculatorOverview() {
    const curriculum = this.curriculum
    const result = this.result
    if (!curriculum || !result) return { dataSource: [] }

    const correctTotal = result.correct_count
    const incorrectTotal = this.onlyFlashCard ? 0 : result.incorrect_count
    const leftoverTotal = result.not_drill_count
    const totalQuestion = curriculum.notDrillCount + curriculum.incorrectCount + curriculum.correctCount
    return {
      dataSource: [correctTotal, incorrectTotal, leftoverTotal],
      correctCount: result.correct_count - curriculum.correctCount,
      incorrectCount: result.incorrect_count - curriculum.incorrectCount,
      leftoverCount: curriculum.notDrillCount - result.not_drill_count,
      correctTotal,
      incorrectTotal,
      leftoverTotal,
      percentCorrect: Math.round((correctTotal / totalQuestion) * 100),
      percentIncorrect: Math.round((incorrectTotal / totalQuestion) * 100),
      percentLeftover: Math.round((leftoverTotal / totalQuestion) * 100),
    }
  }

  private goBack() {
    this.onlyFlashCard
      ? this.$router.go(this.stepBack.nonPageResultDrill)
      : this.$router.go(this.stepBack.hasPageResultDrill)
  }

  private async studyAgain() {
    const params = {
      scopeLevel: this.curriculum.scopeLevel,
      drill_type: this.curriculum.drillType,
      scope_id: this.curriculum.scopeId,
    }
    const title = this.curriculum.title
    const stepBack = this.onlyFlashCard ? this.stepBack.nonPageResultDrill : this.stepBack.hasPageResultDrill
    this.$router.go(stepBack)

    this.startLearning(params, title)
      .then(() => {
        this.push('/quiz/question', title)
      })
      .catch(() => null)
  }

  mounted() {
    if (this.result?.badges?.length) {
      this.modalBadge.show()
      const result: ResultUnitType = this.getResultLocalStorage()
      result.badges = []
      this.saveResult(result)
    }
  }
}
