







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonNext extends Vue {
  @Prop()
  hideIcon?: boolean

  @Prop({default: 'right'})
  placement?: string

  private clickEvent(key: Event): void {
    this.$emit('click', key)
  }
}
