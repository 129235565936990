
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Doughnut, mixins } from 'vue-chartjs'

@Component
export default class ChartDonutQuiz extends Mixins(Doughnut, mixins.reactiveProp) {
  @Prop()
  id?: string

  @Prop({ default: '#ffffff' })
  colorBoder?: string

  @Prop({ default: [] })
  private patternsCustom!: { id: string; default: string; src: string }[]

  @Prop({ default: [] })
  private dataSource!: number[]

  get chartId(): string {
    return this.id || `id${this.$uuid.v4()}`
  }

  private data!: Chart.ChartData

  private patterns: any[] = []

  private options: { [key: string]: any } = {
    animation: {
      animateRotate: true,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 70,
  }

  private checkBorderWidth(dataSource: number[]) {
    let countNumberPositive = 0
    dataSource.forEach((num) => {
      if (num > 0) {
        countNumberPositive++
      }
    })
    return countNumberPositive === 1 ? 0 : 5
  }

  private initChart(dataSource: number[]) {

    this.data = {
      datasets: [
        {
          data: dataSource,
          borderWidth: this.checkBorderWidth(dataSource),
          borderColor: this.colorBoder,
        },
      ],
    }
    this.loadPatterns().then(() => {
      if (!this.data.datasets) return

      this.data.datasets[0].backgroundColor = this.patterns
      // ここで画像読み込み+設定
      setTimeout(() => this.renderChart(this.data, this.options), 100)
    })
  }

  @Watch('dataSource')
  onSubjectsChanged(newData: number[]) {
    this.initChart(newData)
  }

  private loadPatterns(): any {
    // 背景パターンのデフォルト値と画像
    const patternTable = this.patternsCustom
    let promise = Promise.resolve()
    for (let i = 0; i < patternTable.length; i++) {
      promise = promise.then(this.createPattern.bind(this, patternTable[i]))
    }
    return promise
  }

  private createPattern(pattern: { [key: string]: any }): any {
    return this.loadImage(pattern.src).then(
      (img: any) => {
        const canvas = document.getElementById(this.chartId) as HTMLCanvasElement
        const ctx = canvas?.getContext('2d')
        this.patterns.push(ctx?.createPattern(img, 'repeat'))
      },
      () => {
        this.patterns.push(pattern.default)
      }
    )
  }

  private loadImage(src: string): any {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = () => reject()
      img.src = src
    })
  }

  private mounted() {
    this.initChart(this.dataSource)
  }
}
