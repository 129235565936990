










import { Component, Prop, Vue } from 'vue-property-decorator'
import ChartDonutQuiz from '@/components/molecules/quiz/ChartDonutQuiz.vue'

@Component({
  components: {
    ChartDonutQuiz,
  },
})
export default class DonutOverview extends Vue {
  @Prop({ default: [] })
  private dataSource!: number[]

  @Prop({ default: '#ffffff' })
  colorBoder?: string

  private get calculatorDataSource() {
    const total = this.dataSource.reduce((result, current) => (result += current), 0)
    //count number >= 10
    let numberLarger = 0
    let percentArray = this.dataSource.map((item) => {
      let percent = parseFloat(((item / total) * 100).toFixed(1))
      if (percent >= 10) {
        numberLarger++
      }
      if (percent > 0 && percent < 1.5) {
        percent = 1.5
      }
      return percent
    })
    const totalPercent = percentArray.reduce((result, current) => (result += current), 0)
    const leftOver = totalPercent - 100
    if (leftOver > 0) {
      const numberAverage = parseFloat((leftOver / numberLarger).toFixed(1))
      percentArray = percentArray.map((item) => {
        if (item >= 10) {
          return item - numberAverage
        }
        return item
      })
    }
    return percentArray
  }

  private customPattern = [
    { id: 'correct', default: '#4277C8', src: '/quiz-assets/imgs/img-chart-correct.png' },
    { id: 'icorrect', default: '#FF7B62', src: '/quiz-assets/imgs/img-chart-incorrect.png' },
    { id: 'correct', default: '#9DA3AA', src: '' },
  ]
}
