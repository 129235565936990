





import { Prop, Vue, Component } from 'vue-property-decorator'
@Component
export default class Badge extends Vue {
  @Prop({default: 0})
  private value!: number
  @Prop()
  private icon_path!: string
}
