






import { Component, Mixins } from 'vue-property-decorator'
import TooltipQuiz from '@/mixins/quiz/Tooltip'
import BaseQuiz from '@/mixins/quiz/BaseQuiz';

@Component
export default class LabelNameBook extends Mixins(TooltipQuiz, BaseQuiz) {
  private created() {
    this.title = this.nameBook() as string;
  }
}
