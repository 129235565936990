















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import HeaderModal from '@/components/atoms/quiz/HeaderModal.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import BodyModal from '@/components/atoms/quiz/BodyModal.vue'
import { Badge } from '@/types/quiz/units'
import BadgeComponent from '@/components/atoms/quiz/Badge.vue'

@Component({
  components: {
    HeaderModal,
    ModalBase,
    ButtonBase,
    BodyModal,
    BadgeComponent,
  },
})
export default class ModalShowBadge extends Mixins(ModalBaseMethod) {
  @Prop({ default: [] })
  private badges!: Badge[]

  private badge = this.badges[0]

  private isOpen = true

  @Watch('badges', { deep: true })
  watchBadges() {
    this.badge = this.badges[0]
  }

  private index = 0

  private nextBadge() {
    this.hide()
    if (this.index < this.badges.length - 1 && this.isOpen) {
      setTimeout(() => {
        this.badge = this.badges[++this.index]
        this.show()
        this.isOpen = true
      }, 500)
    }
    this.isOpen = false
  }
}
